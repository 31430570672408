import React, { useState } from "react";
import { Layout } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import url from "../../../src/Development.json";
const { Dragger } = Upload;

const Dashboard = () => {

    const { Content } = Layout;
    const accessToken = localStorage.getItem("accessToken") || null

    const props = {
        name: 'pdf_file',
        action: `${process.env.REACT_APP_BASE_URL + url.upload_pdf}`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Content className="site-layout-background">

            <div className="site-card-border-less-wrapper mt-2">
                <h1>Upload your PDF here</h1>
                <br />
                <Dragger
                    {...props}
                    maxCount={1}
                    className="pdf_upload_dragger"
                // onChange={fileUploadChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                        banned files.
                    </p>
                </Dragger>
                <br />
                {/* <div className="upload_btn">
                    <Button type="primary" size={"large"} onClick={handleUpload}>
                        Upload
                    </Button>
                </div> */}
            </div>
        </Content>
    )
};

export default Dashboard;
