import React, { useEffect, useState } from "react";
import { Layout, Menu, Image } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
    HomeOutlined,
    UsergroupAddOutlined,
    UserAddOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    StrikethroughOutlined,
    WindowsOutlined,
    StockOutlined,
    DingtalkOutlined,
    DollarOutlined
} from '@ant-design/icons';
import logo from '../../../assets/images/logo.png'

const Sidebar = ({ collapsed }) => {
    const { Sider } = Layout;
    const navigate = useNavigate();
    const location = useLocation();
    const [current, setCurrent] = useState(location.pathname)

    const onMenuClick = (event) => {
        const { key } = event
        navigate(key)
        setCurrent(key)
    }

    const items = [
        {
            label: 'Upload',
            key: '/',
            icon: <HomeOutlined />
        },
        {
            label: 'Orders',
            key: '/orders',
            icon: <UsergroupAddOutlined />
        },
    ];

    return <>
        <Sider className="left_sidebar" trigger={null} collapsible collapsed={collapsed}>
            <div className="demo-logo-vertical" />
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['/']}
                onClick={onMenuClick}
                selectedKeys={[current]}
                items={items}
            />
        </Sider>
    </>

}
export default Sidebar;