import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { Spinner } from 'react-bootstrap';
import url from "../../../Development.json";

const index = () => {

    const [isLoading, setIsLoading] = useState(false);
    const accessToken = localStorage.getItem("accessToken") || null;

    const onFinish = async (values) => {
        setIsLoading(true);

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${url.change_password}`,
            values,
            headers: { 'Authorization': 'Bearer ' + accessToken }
        };

        axios(config).then((response) => {
            debugger
            let data = response.data;
            localStorage.setItem(
                "accessToken",
                data.token
            );
            localStorage.setItem(
                "profile",
                JSON.stringify(data)
            );
            setIsLoading(false);
            message.success("Password has been updated");
        })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    console.log("error.response ====", error.response);
                    message.error(error.response);
                }
            });
    };
    const style = {
        height: "500px"
    }
    return (
        <div className='login-form-side' style={style}>
            <h2 className='fw-bold login_form_heading'>
                Change password
            </h2>
            <br />
            <div className='login_form_container'>
                <Form
                    name="login"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="Current password"
                        name="current_password"
                        rules={[
                            {
                                required: true,
                                type: 'string',
                                min: 5,
                                max: 15,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" size='large' />
                    </Form.Item>
                    <Form.Item
                        label="New password"
                        name="new_password"
                        rules={[
                            {
                                required: true,
                                type: 'string',
                                min: 5,
                                max: 15,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" size='large' />
                    </Form.Item>
                    <Form.Item
                        label="Confirm password"
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                type: 'string',
                                min: 5,
                                max: 15,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" size='large' />
                    </Form.Item>
                    <div className='center text-center'>
                        <Button type="primary" htmlType="submit" className='login-button-color' block size='large'>
                            {isLoading ? <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                            </>
                                : ''
                            }
                            {isLoading ? 'loading...' : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default index