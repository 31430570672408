import React from 'react';
import { Layout } from 'antd';
import { Button, theme, Avatar } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import url from "../../../Development.json";
import { Dropdown } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const HeaderPage = ({ collapsed, setCollapsed }) => {
    const { Header } = Layout;
    const navigate = useNavigate();
    const accessToken = localStorage.getItem("accessToken") || null

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const buttonStyle = {
        backgroundColor: '#f00',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const avatarStyle = {
        position: 'fixed',
        top: '12px',
        right: '20px',
        backgroundColor: "#f56a00",
        verticalAlign: 'middle',
    }

    const onLogout = async () => {

        try {

            const response = await axios({ method: 'get', url: process.env.REACT_APP_BASE_URL + url.logout, headers: { 'Authorization': 'Bearer ' + accessToken } })
            debugger
            if (response && response.status === 200) {
                localStorage.removeItem('accessToken')
                navigate("/login")
            }
        } catch (error) {
            toast.success(`${error?.message} !`, {
                position: toast.POSITION.TOP_CENTER
            });
        }


    }

    const items = [
        {
            label: (
                <Link to="/profile">
                    Profile
                </Link>
            ),
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: <>
                <Button style={buttonStyle}
                    onClick={onLogout}
                    size='large'
                >
                    Logout
                </Button>
            </>,
            key: '3',
            disabled: true,
        },
    ];

    return (
        <Header
            style={{
                padding: 0,
                background: colorBgContainer,
            }}
        >
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                }}
            />

            <Dropdown
                menu={{
                    items,
                }}
            >
                <Avatar

                    size="large"
                    style={avatarStyle}
                >
                    U
                </Avatar>
            </Dropdown>

        </Header>
    )

}
export default HeaderPage;