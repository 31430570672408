// import Logout from "../pages/view/Logout";
import Login from "../pages/view/Login";
import Dashboard from "../pages/Dashboard";
import OrdersListPage from "../pages/Orders";
import Profile from "../pages/Profile";

const routes = [
    {
        path: "/login",
        exact: true,
        auth: false,
        component: <Login title="login" />
    },
    {
        path: "/",
        exact: true,
        auth: true,
        component: <Dashboard title="dashboard" />
    },
    {
        path: "/profile",
        exact: true,
        auth: true,
        component: <Profile title="profile" />
    },
    {
        path: "/orders",
        exact: true,
        auth: true,
        component: <OrdersListPage title="Orders" />
    },
]

export default routes;