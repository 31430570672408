import { Layout } from "antd";
import { Table, Tag, Button } from 'antd';
import { useEffect, useState } from "react";
import { message } from 'antd';
import axios from "axios";
import url from "../../../Development.json";
import { PDFDocument, PDFName } from 'pdf-lib';
import {
    PlusSquareOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
const OrdersListPage = () => {
    const { Content } = Layout;
    const accessToken = localStorage.getItem("accessToken") || null
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState(0);
    let navigate = useNavigate();

    const mergePDFs = async (pdfURLs) => {
        try {
            const pdfDoc = await PDFDocument.create();

            for (const url of pdfURLs) {
                const externalPdfDoc = await PDFDocument.load(url);
                const copiedPages = await pdfDoc.copyPages(externalPdfDoc, externalPdfDoc.getPageIndices());
                copiedPages.forEach((page) => pdfDoc.addPage(page));
            }

            const mergedPDFBytes = await pdfDoc.save();
            // Load the PDF from Uint8Array
            const pdfDoc2 = await PDFDocument.load(mergedPDFBytes);

            // Convert the PDF to a blob
            const pdfBytes = await pdfDoc2.save();
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open a new window with the PDF URL
            const printWindow = window.open(pdfUrl, '_blank');

            printWindow.print();
            setLoading(false)

        } catch (error) {
            console.error('Error merging PDFs:', error);
        }
    };

    const handlePrint = async (dataSource) => {
        debugger
        const data = {}
        setLoading(true)
        try {

            data["orderid"] = dataSource.id
            data["skus"] = dataSource.children.map(child => child.sku)

            const response = await axios({ method: 'post', url: process.env.REACT_APP_BASE_URL + url.pdf_documents_sku, data, headers: { 'Authorization': 'Bearer ' + accessToken } })
            if (response && response.status === 200) {
                debugger

                let populatedPdfs = []

                for (let index = 0; index < dataSource.children.length; index++) {
                    const element = dataSource.children[index];
                    for (let index = 0; index < element.quantity; index++) {
                        const pdfContent = response.data.pdf_files.find((file) => file.sku === element.sku)
                        populatedPdfs.push(pdfContent.pdf_content)
                    }
                }
                debugger

                mergePDFs(populatedPdfs)
                // mergePDFs(["https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"])
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const columns = [
        {
            title: 'Order Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Status',
            key: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (data) =>
                data.status === 1 ? <Tag color={"green"}>
                    {"Printed".toUpperCase()}
                </Tag> :
                    <Tag color={"volcano"}>
                        {"Not Printed".toUpperCase()}
                    </Tag>
        },
        {
            title: 'Print',
            key: 'status',
            render: (data, dataSource) => <Button type="primary" danger onClick={() => handlePrint(dataSource)}>
                Print
            </Button>
        },
    ];

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios({ method: 'get', url: process.env.REACT_APP_BASE_URL + url.webhook_shopify_get, headers: { 'Authorization': 'Bearer ' + accessToken } })
                if (response && response.status === 200) {
                    const updatedOrders = response.data.data.map((data, index) => {
                        return {
                            id: data.id,
                            key: data.id,
                            status: data.status,
                            children: data.line_items.map((lineItem) => {
                                return {
                                    key: index,
                                    ...lineItem
                                }
                            })
                        }
                    })
                    setOrders(updatedOrders)
                }
            } catch (error) {
                message.error("Failed to fetch orders")
                if (error?.response?.status) {
                    debugger
                    if (error?.response?.status === 401) {
                        // redirect("/login")
                        message.error(error?.message)
                        setLoading(false)
                        navigate("/login")

                    }
                }
                // navigate("/")
            }
        }
        fetchOrders()
    }, [])

    const expandedRowRender = (record) => {
        const childColumns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
            { title: 'sku', dataIndex: 'sku', key: 'sku' },
        ];
        // debugger
        return (
            <Table
                columns={childColumns}
                dataSource={record.children}
                pagination={false}
            />
        );
    };
    const handleExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys(record.key);
        } else {
            setExpandedRowKeys(null);
        }
    };
    const expandable = {
        expandedRowKeys: [expandedRowKeys],
        expandedRowRender,
        expandRowByClick: true,
        onExpand: handleExpand,
        rowExpandable: record => record.children && record?.children?.length > 0,
    };

    return (
        <>
            <Content className="site-layout-background">

                <div className="site-card-border-less-wrapper-orders mt-2">
                    <h1>Orders</h1>
                    <div>
                        <Table
                            columns={columns}
                            dataSource={orders}
                            expandable={expandable}
                            // pagination={false}
                            footer={null}
                            loading={loading}
                        />
                    </div>
                    <br />
                </div>
            </Content>
        </>
    )
}

export default OrdersListPage