import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Spinner } from 'react-bootstrap';
import url from "../../../Development.json";

import axios from 'axios';
import { Navigate } from 'react-router-dom';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [loggedIn, setLoggedIn] = useState(false);

    // useEffect(() => {
    //     document.body.className = 'login-body';
    //     document.title = process.env.REACT_APP_APP_NAME + ' Login';

    //     const isLogin = localStorage.getItem("accessToken") || false;
    //     if (isLogin) {
    //         window.location.href = "/";
    //     }

    // }, []);

    const onFinish = async (values) => {
        setIsLoading(true);
        const data = {
            email: values.email,
            password: values.password,
        }
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${url.login}`,
            data,
        };

        axios(config).then((response) => {
            debugger
            let data = response.data;
            localStorage.setItem(
                "accessToken",
                data.token
            );
            localStorage.setItem(
                "profile",
                JSON.stringify(data)
            );
            setIsLoading(false);
            setLoggedIn(true);
        })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    console.log("error.response ====", error.response);
                    // errorResponse(error);
                }
            });
    };

    if (loggedIn) {
        return <Navigate to="/" />
    }

    return (
        <div className='full-height'>
            <div className="center align-items-center d-flex h-100 justify-content-center">
                <div className='login-form-side'>
                    <h2 className='fw-bold login_form_heading'>
                        Sign in to Admin Panel
                    </h2>
                    <br />
                    <div className='login_form_container'>
                        <Form
                            name="login"
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            // validateMessages={validateMessages()}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email address"
                                name="email"
                                id="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        min: 7,
                                        max: 15,
                                    },
                                ]}
                            >
                                <Input placeholder="Email address" size='large' />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        type: 'string',
                                        min: 5,
                                        max: 15,
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Password" size='large' />
                            </Form.Item>
                            <div className='center text-center'>
                                <Button type="primary" htmlType="submit" className='login-button-color' block size='large'>
                                    {isLoading ? <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                    </>
                                        : ''
                                    }
                                    {isLoading ? 'loading...' : 'Login'}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Login;