/* eslint-disable react/prop-types */
import React from "react";
import { useEffect, useState } from 'react';
import { Layout } from "antd";
import Header from './Header';
import { theme } from 'antd';
import { Outlet } from 'react-router-dom';

import Sidebar from "./Sidebar"

const Main = (props) => {
    const { Content } = Layout;
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { ptitle } = props;
    useEffect(() => {
        document.title = process.env.REACT_APP_APP_NAME + ' ' + ptitle;
    }, [ptitle]);


    useEffect(() => {
        const isLogin = localStorage.getItem("accessToken") || false;
        if (!isLogin) {
            // window.location.href = "/login";
        }

    }, []);

    return (
        <>
            <Layout>
                <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
                <Layout>
                    <Header collapsed={collapsed} setCollapsed={setCollapsed} />
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default Main;
