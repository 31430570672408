import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  HashRouter
} from "react-router-dom";
import Master from "../pages/layout/Main";
import routes from "./routes";

const RoutePage = () => {
  const isLogin = localStorage.getItem("accessToken") ? true : false;
  return (
    <HashRouter>
      <Routes>
        {routes.map(({ path, auth, component: Component }, key) => {
          return auth === true && isLogin === true ? (
            <Route
              path="/"
              element={<Master ptitle={Component.props.title} />}
              key={"layouts_auth" + key}
            >
              <Route exact path={path} element={Component} />
            </Route>
          ) : auth === true && isLogin === false ? (
            <Route
              path="/"
              element={<Navigate replace to="/login" />}
              key={"layouts_false_auth" + key}
            >
              <Route
                exact
                path={path}
                element={<Navigate replace to="/login" />}
              />
            </Route>
          ) : (
            <Route exact path={path} element={Component} key={"login_" + key} />
          );
        })}
      </Routes>
    </HashRouter>
  );
};
export default RoutePage;
